import { FC } from 'react'

export const MenuToggle: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14V12H16V14H0ZM0 9V7H16V9H0ZM0 4V2L16 2V4H0Z" fill="currentColor" />
  </svg>
)
export const ArrUp: FC = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.60983 2.17757C4.82535 1.94081 5.17465 1.94081 5.39017 2.17757L8.83711 5.96416C9.18498 6.34631 8.93876 7 8.44694 7L1.55306 7C1.06124 7 0.815015 6.34631 1.16289 5.96416L4.60983 2.17757Z" />
  </svg>
)
export const ArrDown: FC = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.39017 7.82243C5.17465 8.05919 4.82535 8.05919 4.60983 7.82243L1.16289 4.03584C0.815015 3.65369 1.06124 3 1.55306 3L8.44694 3C8.93876 3 9.18499 3.65369 8.83711 4.03584L5.39017 7.82243Z" />
  </svg>
)
export const Star: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3634 13.5198L20.7118 10.3288C20.9617 10.1433 21.0617 9.83107 20.9617 9.54806C20.8617 9.26506 20.5918 9.06989 20.2819 9.06989H14.5441L12.6748 3.47817C12.5848 3.19517 12.3049 3 11.995 3C11.6851 3 11.4152 3.19517 11.3153 3.47817L9.45594 9.06989H3.71806C3.40817 9.06989 3.13827 9.26506 3.03831 9.54806C2.93834 9.83107 3.03831 10.1433 3.28821 10.3288L7.63661 13.5198L6.05719 19.1213C5.97722 19.4141 6.09718 19.7166 6.34709 19.8825C6.60699 20.0484 6.93687 20.0386 7.18678 19.8532L11.995 16.1937L16.8032 19.8532C17.0431 20.0386 17.383 20.0484 17.6429 19.8825C17.9028 19.7166 18.0128 19.4043 17.9328 19.1213L16.3634 13.5198Z" />
  </svg>
)
export const Calendar: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 20H5C4.45 20 4 19.55 4 19V7C4 6.45 4.45 6 5 6H7V7C7 7.55 7.45 8 8 8C8.55 8 9 7.55 9 7V6H15V7C15 7.55 15.45 8 16 8C16.55 8 17 7.55 17 7V6H19C19.55 6 20 6.45 20 7V19C20 19.55 19.55 20 19 20ZM16 16V17H17V16H16ZM13 16V17H14V16H13ZM10 16V17H11V16H10ZM7 16V17H8V16H7ZM16 14V15H17V14H16ZM13 14V15H14V14H13ZM10 14V15H11V14H10ZM7 14V15H8V14H7ZM16 12V13H17V12H16ZM13 12V13H14V12H13ZM10 12V13H11V12H10ZM7 12V13H8V12H7ZM16 10V11H17V10H16ZM13 10V11H14V10H13ZM10 10V11H11V10H10ZM7 10V11H8V10H7Z" />
    <path
      opacity="0.5"
      d="M15 7V4C15 3.45 15.45 3 16 3C16.55 3 17 3.45 17 4V7C17 7.55 16.55 8 16 8C15.45 8 15 7.55 15 7ZM7 7V4C7 3.45 7.45 3 8 3C8.55 3 9 3.45 9 4V7C9 7.55 8.55 8 8 8C7.45 8 7 7.55 7 7Z"
    />
  </svg>
)
export const Projects: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M21 20H9C7.9 20 7 19.1 7 18V15H15C16.1 15 17 14.1 17 13V9H21C22.1 9 23 9.9 23 11V18C23 19.1 22.1 20 21 20Z"
    />
    <path d="M15 15H3C1.9 15 1 14.1 1 13V6C1 4.9 1.9 4 3 4H15C16.1 4 17 4.9 17 6V13C17 14.1 16.1 15 15 15ZM8 7C7.72 7 7.5 7.22 7.5 7.5V11.5C7.5 11.78 7.72 12 8 12C8.11 12 8.21 11.96 8.3 11.9L10.97 9.9C11.1 9.81 11.17 9.66 11.17 9.5C11.17 9.34 11.1 9.19 10.97 9.1L8.3 7.1C8.21 7.03 8.11 7 8 7Z" />
  </svg>
)
export const Clock: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 6C11.72 6 11.5 6.22 11.5 6.5V12C11.5 12.15 11.56 12.29 11.68 12.38L11.69 12.39L11.7 12.41L14.7 15.41C14.79 15.5 14.92 15.56 15.05 15.56C15.18 15.56 15.31 15.51 15.4 15.41C15.49 15.32 15.55 15.19 15.55 15.06C15.55 14.93 15.5 14.8 15.4 14.71L12.5 11.79V6.5C12.5 6.22 12.28 6 12 6Z" />
  </svg>
)
export const Update: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C6 15.31 8.69 18 12 18C13.41 18 14.78 17.51 15.86 16.6L17.15 18.13C15.7 19.34 13.88 20 12 20C7.58 20 4 16.42 4 12H1L5 7L9 12H6ZM18 12C18 8.69 15.31 6 12 6C10.59 6 9.22 6.49 8.14 7.4L6.86 5.87C8.3 4.66 10.12 4 12 4C16.42 4 20 7.58 20 12H23L19 17L15 12H18Z"
    />
  </svg>
)
export const User: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.97 18.7C19 20.3 15.97 21 12 21C8.03 21 5 20.3 5 18.7C5 17.1 8.7 16.7 9.95 15.36C10.26 14.82 10.35 14.18 10.2 13.57C9.76 13.37 9.4 12.76 9.38 11.45C8.97 11.46 8.71 11.32 8.64 11.1C8.21 10.33 8.21 9.39 8.64 8.62C7.83 5.68 9.75 4 11.99 4C14.23 4 16.15 5.68 15.34 8.61C15.77 9.38 15.77 10.32 15.34 11.09C15.27 11.32 15 11.46 14.59 11.45C14.57 12.76 14.23 13.37 13.79 13.58C13.62 14.18 13.7 14.82 13.99 15.36C15.25 16.7 18.94 17.1 18.97 18.7Z"
    />
  </svg>
)
export const Logout: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.94C11.24 3.32 12.61 3 14 3C18.97 3 23 7.03 23 12C23 16.97 18.97 21 14 21C12.61 21 11.24 20.68 10 20.06V16.47C11.1 17.46 12.52 18 14 18C17.31 18 20 15.31 20 12C20 8.69 17.31 6 14 6C12.52 6 11.1 6.54 10 7.53V3.94ZM8 10.32H13.82C14.75 10.32 15.5 11.07 15.5 12C15.5 12.93 14.75 13.68 13.82 13.68H8V18L2 12L8 6V10.32Z"
    />
  </svg>
)
export const Gear: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.95 2.05C12.31 1.99 11.67 1.99 11.04 2.05L10.65 3.98C10.14 4.07 9.64001 4.2 9.15 4.38L7.85 2.9C7.27 3.17 6.71 3.49 6.19 3.86L6.82 5.73C6.42 6.06 6.05 6.43 5.72 6.83L3.86 6.19C3.48 6.71 3.16 7.27 2.9 7.85L4.38 9.15C4.2 9.64001 4.07 10.14 3.98 10.65L2.05 11.04C1.99 11.68 1.99 12.32 2.05 12.95L3.98 13.34C4.07 13.85 4.2 14.35 4.38 14.84L2.9 16.15C3.17 16.73 3.49 17.29 3.86 17.81L5.73 17.18C6.06 17.58 6.43 17.95 6.83 18.28L6.2 20.15C6.72 20.52 7.28 20.84 7.86 21.11L9.16 19.63C9.65 19.81 10.15 19.94 10.66 20.03L11.05 21.97C11.69 22.03 12.33 22.03 12.96 21.97L13.35 20.04C13.86 19.95 14.36 19.82 14.85 19.64L16.15 21.12C16.73 20.85 17.29 20.53 17.81 20.16L17.18 18.29C17.58 17.96 17.95 17.59 18.28 17.19L20.15 17.82C20.52 17.3 20.84 16.74 21.11 16.16L19.63 14.86C19.81 14.37 19.94 13.87 20.03 13.36L21.96 12.97C22.02 12.33 22.02 11.69 21.96 11.05L20.03 10.66C19.94 10.15 19.81 9.65 19.63 9.16L21.11 7.86C20.84 7.28 20.52 6.72 20.15 6.2L18.28 6.83C17.95 6.43 17.58 6.06 17.18 5.73L17.81 3.86C17.29 3.49 16.73 3.17 16.15 2.9L14.85 4.38C14.36 4.2 13.86 4.07 13.35 3.98L12.95 2.05ZM11.99 5.5C15.58 5.5 18.49 8.41 18.49 12C18.49 15.59 15.58 18.5 11.99 18.5C8.4 18.5 5.49 15.59 5.49 12C5.49 8.41 8.4 5.5 11.99 5.5ZM17.74 12.33C17.56 15.37 15.05 17.75 12 17.75C11.1 17.75 10.22 17.54 9.42 17.14L12.19 12.34H17.74V12.33ZM8.84 7.2C7.22001 8.26 6.25 10.07 6.25 12C6.25 13.93 7.22001 15.74 8.84 16.8L11.61 12L8.84 7.2ZM17.74 11.67C17.56 8.63 15.05 6.25 12 6.25C11.1 6.25 10.22 6.46 9.42 6.86L12.19 11.67H17.74Z"
    />
  </svg>
)
export const Home: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20H5V13.5L12 7.5L19 13.5V20H14V16C14 15.45 13.55 15 13 15H11C10.45 15 10 15.45 10 16V20ZM4 13L12 6L20 13L21 12L12 4L3 12L4 13Z"
    />
  </svg>
)
export const Project: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 19H4C2.9 19 2 18.1 2 17V7C2 5.9 2.9 5 4 5H20C21.1 5 22 5.9 22 7V17C22 18.1 21.1 19 20 19ZM10.5 8.54C10.22 8.54 10 8.76 10 9.04V14.96C10 15.24 10.22 15.46 10.5 15.46C10.61 15.46 10.72 15.42 10.81 15.35L14.51 12.39C14.63 12.29 14.7 12.15 14.7 12C14.7 11.85 14.63 11.7 14.51 11.61L10.81 8.65C10.72 8.58 10.61 8.54 10.5 8.54Z" />
  </svg>
)
export const Reels: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.91 19C17.7 18.4 17.13 18 16.5 18H14.98C16.28 16.46 17 14.51 17 12.5C17 11.73 16.9 10.97 16.69 10.23L16.8 10.26L17.9 8.87C17.34 8.62 16.72 8.5 16.1 8.5H16C15.73 8 15.41 7.53 15.05 7.09C15.4 7.03 15.75 7 16.1 7C19.44 6.98 22.17 9.66 22.19 13C22.18 15.16 21.02 17.14 19.14 18.2H22.6C22.82 18.2 23 18.37 23.01 18.59C23.01 18.59 23.01 18.59 23.01 18.6C23.01 18.82 22.83 19 22.61 19C22.61 19 22.61 19 22.6 19H17.91ZM17.15 15.63L17.09 17.39C18.31 17.12 19.37 16.36 20.02 15.29L18.31 14.8C18 15.17 17.6 15.45 17.15 15.63ZM18.52 11.48C18.78 11.88 18.93 12.34 18.96 12.82L20.64 13.42C20.65 13.28 20.66 13.14 20.66 13C20.66 11.9 20.25 10.84 19.52 10.03L18.52 11.48Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 20C4.36 20 1 16.64 1 12.5C1 8.36 4.36 5 8.5 5C12.64 5 16 8.36 16 12.5C16 15.18 14.57 17.66 12.24 19H16.5C16.78 19 17 19.22 17 19.5C17 19.78 16.78 20 16.5 20H8.5ZM3.66 15.37C4.45 16.7 5.76 17.65 7.27 17.99L7.2 15.79C6.65 15.57 6.16 15.22 5.78 14.76L3.66 15.37ZM13.34 15.37L11.23 14.76C10.85 15.22 10.36 15.57 9.81 15.79L9.74 17.99C11.24 17.65 12.55 16.7 13.34 15.37ZM4.28 8.78C3.37 9.81 2.87 11.13 2.88 12.5C2.88 12.68 2.89 12.85 2.9 13.02L4.97 12.27C5.01 11.68 5.2 11.11 5.51 10.61L4.28 8.78ZM12.72 8.78L11.48 10.6C11.8 11.1 11.99 11.68 12.02 12.27L14.09 13.02C14.11 12.85 14.11 12.67 14.11 12.49C14.13 11.13 13.63 9.81 12.72 8.78ZM10.73 7.34C10.03 7.04 9.27 6.88 8.5 6.88C7.73 6.88 6.97 7.04 6.27 7.34L7.62 9.08C8.2 8.93 8.8 8.93 9.38 9.08L10.73 7.34Z"
    />
  </svg>
)
export const Sequence: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M22 7H2V17H22V7Z" />
    <path d="M20.5 20H3.5C2.67 20 2 19.33 2 18.5V17H5C5.55 17 6 16.55 6 16V8C6 7.45 5.55 7 5 7H2V5.5C2 4.67 2.67 4 3.5 4H20.5C21.33 4 22 4.67 22 5.5V7H19C18.45 7 18 7.45 18 8V16C18 16.55 18.45 17 19 17H22V18.5C22 19.33 21.33 20 20.5 20ZM19.3 17.5C19.02 17.5 18.8 17.72 18.8 18V19C18.8 19.28 19.02 19.5 19.3 19.5H19.7C19.98 19.5 20.2 19.28 20.2 19V18C20.2 17.72 19.98 17.5 19.7 17.5H19.3ZM16.3 17.5C16.02 17.5 15.8 17.72 15.8 18V19C15.8 19.28 16.02 19.5 16.3 19.5H16.7C16.98 19.5 17.2 19.28 17.2 19V18C17.2 17.72 16.98 17.5 16.7 17.5H16.3ZM13.3 17.5C13.02 17.5 12.8 17.72 12.8 18V19C12.8 19.28 13.02 19.5 13.3 19.5H13.7C13.98 19.5 14.2 19.28 14.2 19V18C14.2 17.72 13.98 17.5 13.7 17.5H13.3ZM10.3 17.5C10.02 17.5 9.8 17.72 9.8 18V19C9.8 19.28 10.02 19.5 10.3 19.5H10.7C10.98 19.5 11.2 19.28 11.2 19V18C11.2 17.72 10.98 17.5 10.7 17.5H10.3ZM7.3 17.5C7.02 17.5 6.8 17.72 6.8 18V19C6.8 19.28 7.02 19.5 7.3 19.5H7.7C7.98 19.5 8.2 19.28 8.2 19V18C8.2 17.72 7.98 17.5 7.7 17.5H7.3ZM4.3 17.5C4.02 17.5 3.8 17.72 3.8 18V19C3.8 19.28 4.02 19.5 4.3 19.5H4.7C4.98 19.5 5.2 19.28 5.2 19V18C5.2 17.72 4.98 17.5 4.7 17.5H4.3ZM9 7C8.45 7 8 7.45 8 8V16C8 16.55 8.45 17 9 17H15C15.55 17 16 16.55 16 16V8C16 7.45 15.55 7 15 7H9ZM19.3 4.5C19.02 4.5 18.8 4.72 18.8 5V6C18.8 6.28 19.02 6.5 19.3 6.5H19.7C19.98 6.5 20.2 6.28 20.2 6V5C20.2 4.72 19.98 4.5 19.7 4.5H19.3ZM16.3 4.5C16.02 4.5 15.8 4.72 15.8 5V6C15.8 6.28 16.02 6.5 16.3 6.5H16.7C16.98 6.5 17.2 6.28 17.2 6V5C17.2 4.72 16.98 4.5 16.7 4.5H16.3ZM13.3 4.5C13.02 4.5 12.8 4.72 12.8 5V6C12.8 6.28 13.02 6.5 13.3 6.5H13.7C13.98 6.5 14.2 6.28 14.2 6V5C14.2 4.72 13.98 4.5 13.7 4.5H13.3ZM10.3 4.5C10.02 4.5 9.8 4.72 9.8 5V6C9.8 6.28 10.02 6.5 10.3 6.5H10.7C10.98 6.5 11.2 6.28 11.2 6V5C11.2 4.72 10.98 4.5 10.7 4.5H10.3ZM7.3 4.5C7.02 4.5 6.8 4.72 6.8 5V6C6.8 6.28 7.02 6.5 7.3 6.5H7.7C7.98 6.5 8.2 6.28 8.2 6V5C8.2 4.72 7.98 4.5 7.7 4.5H7.3ZM4.3 4.5C4.02 4.5 3.8 4.72 3.8 5V6C3.8 6.28 4.02 6.5 4.3 6.5H4.7C4.98 6.5 5.2 6.28 5.2 6V5C5.2 4.72 4.98 4.5 4.7 4.5H4.3Z" />
  </svg>
)
export const Shot: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path opacity="0.3" d="M19 5H5V19H19V5Z" />
      <path d="M20.5 19H3.5C2.67 19 2 18.33 2 17.5V6.5C2 5.67 2.67 5 3.5 5H20.5C21.33 5 22 5.67 22 6.5V17.5C22 18.33 21.33 19 20.5 19ZM6.5 6C5.67 6 5 6.67 5 7.5V16.5C5 17.33 5.67 18 6.5 18H17.5C18.33 18 19 17.33 19 16.5V7.5C19 6.67 18.33 6 17.5 6H6.5ZM20 16C19.72 16 19.5 16.22 19.5 16.5V17C19.5 17.28 19.72 17.5 20 17.5H21C21.28 17.5 21.5 17.28 21.5 17V16.5C21.5 16.22 21.28 16 21 16H20ZM3 16C2.72 16 2.5 16.22 2.5 16.5V17C2.5 17.28 2.72 17.5 3 17.5H4C4.28 17.5 4.5 17.28 4.5 17V16.5C4.5 16.22 4.28 16 4 16H3ZM20 13C19.72 13 19.5 13.22 19.5 13.5V14C19.5 14.28 19.72 14.5 20 14.5H21C21.28 14.5 21.5 14.28 21.5 14V13.5C21.5 13.22 21.28 13 21 13H20ZM3 13C2.72 13 2.5 13.22 2.5 13.5V14C2.5 14.28 2.72 14.5 3 14.5H4C4.28 14.5 4.5 14.28 4.5 14V13.5C4.5 13.22 4.28 13 4 13H3ZM20 10C19.72 10 19.5 10.22 19.5 10.5V11C19.5 11.28 19.72 11.5 20 11.5H21C21.28 11.5 21.5 11.28 21.5 11V10.5C21.5 10.22 21.28 10 21 10H20ZM3 10C2.72 10 2.5 10.22 2.5 10.5V11C2.5 11.28 2.72 11.5 3 11.5H4C4.28 11.5 4.5 11.28 4.5 11V10.5C4.5 10.22 4.28 10 4 10H3ZM20 7C19.72 7 19.5 7.22 19.5 7.5V8C19.5 8.28 19.72 8.5 20 8.5H21C21.28 8.5 21.5 8.28 21.5 8V7.5C21.5 7.22 21.28 7 21 7H20ZM3 7C2.72 7 2.5 7.22 2.5 7.5V8C2.5 8.28 2.72 8.5 3 8.5H4C4.28 8.5 4.5 8.28 4.5 8V7.5C4.5 7.22 4.28 7 4 7H3Z" />
      <path d="M7.93 17C7.65 17 7.43 16.78 7.43 16.5C7.43 16.4 7.46 16.3 7.51 16.22L9 13.98C9.72 15.07 10.48 16.23 10.48 16.23L13.49 11.62L16.57 16.29C16.7 16.52 16.62 16.8 16.39 16.93C16.31 16.97 16.22 17 16.13 16.99L7.93 17Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="14" fill="white" transform="translate(2 5)" />
      </clipPath>
    </defs>
  </svg>
)
export const Check: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21C7.03 21 3 16.97 3 12C3 7.03 7.03 3 12 3C16.97 3 21 7.03 21 12C20.99 16.97 16.97 20.99 12 21ZM7 10C6.45 10 6 10.45 6 11C6 11.27 6.1 11.52 6.29 11.71L11 16.41L17.71 9.7C18.11 9.32 18.12 8.68 17.73 8.29C17.35 7.89 16.71 7.88 16.32 8.27C16.31 8.28 16.3 8.29 16.3 8.29L11 13.59L7.71 10.29C7.52 10.1 7.27 10 7 10Z" />
  </svg>
)
export const Graph: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M9 11.44H9.06C10.4 11.41 11.47 10.31 11.48 8.97001V7.47001C11.48 6.53001 11.85 5.64001 12.51 4.98001C13.17 4.32001 14.06 3.95001 15 3.95001V4.99001C14.34 4.99001 13.71 5.25001 13.25 5.72001C12.79 6.18001 12.52 6.81001 12.52 7.47001V8.98001C12.52 10.21 11.87 11.36 10.81 11.99C11.87 12.61 12.51 13.75 12.52 14.98V16.47C12.52 17.12 12.78 17.75 13.25 18.2C13.72 18.66 14.35 18.92 15 18.92V19.95C14.07 19.95 13.17 19.58 12.51 18.93C11.85 18.28 11.48 17.39 11.48 16.47V14.98C11.47 13.64 10.4 12.56 9.06 12.53H9V11.44Z"
    />
    <path d="M2 9H8C8.55 9 9 9.45 9 10V14C9 14.55 8.55 15 8 15H2C1.45 15 1 14.55 1 14V10C1 9.45 1.45 9 2 9Z" />
    <path d="M16 2H22C22.55 2 23 2.45 23 3V6C23 6.55 22.55 7 22 7H16C15.45 7 15 6.55 15 6V3C15 2.45 15.45 2 16 2Z" />
    <path d="M16 17H22C22.55 17 23 17.45 23 18V21C23 21.55 22.55 22 22 22H16C15.45 22 15 21.55 15 21V18C15 17.45 15.45 17 16 17Z" />
  </svg>
)
export const Stuff: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 19H4.5C3.67 19 3 18.33 3 17.5V8.99998H21V17.5C21 18.33 20.33 19 19.5 19ZM5 15V15.75H10V15H5ZM5 13V13.75H12V13H5ZM5 11V11.75H12V11H5ZM21 8.24998H12.25V4.99998H18.45C18.78 4.98998 19.09 5.15998 19.27 5.43998C19.43 5.69998 20.51 7.44998 20.97 8.19998L21 8.24998ZM11.75 8.24998H3C3 8.23998 3.15 7.99998 3.35 7.66998C3.83 6.88998 4.64 5.57998 4.77 5.35998C4.96 5.10998 5.26 4.96998 5.58 4.99998H11.75V8.24998Z" />
  </svg>
)
export const People: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M12.01 18.02C10.41 18.61 8.71 18.86 7.01 18.77C3.6 18.77 1 18.16 1 16.77C1 15.38 4.18 15.03 5.25 13.87C5.52 13.4 5.59 12.85 5.47 12.32C5.09 12.14 4.79 11.61 4.77 10.47C4.41 10.48 4.19 10.36 4.13 10.17C3.76 9.5 3.76 8.68 4.13 8.01C3.43 5.46 5.08 4 7 4C8.92 4 10.58 5.46 9.88 8.01C10.25 8.68 10.25 9.5 9.88 10.17C9.82 10.36 9.59 10.48 9.24 10.47C9.22 11.61 8.93 12.14 8.56 12.32C8.41 12.84 8.47 13.4 8.73 13.87C9.42 14.61 10.96 15.02 11.99 15.59C13.02 15.03 14.56 14.62 15.25 13.87C15.51 13.4 15.59 12.85 15.46 12.32C15.08 12.14 14.78 11.61 14.76 10.47C14.41 10.48 14.18 10.36 14.12 10.16C13.75 9.49 13.75 8.67 14.12 8C13.42 5.46 15.08 4 17 4C18.92 4 20.58 5.46 19.88 8.01C20.25 8.68 20.25 9.5 19.88 10.17C19.82 10.36 19.59 10.48 19.24 10.48C19.22 11.62 18.93 12.15 18.55 12.33C18.41 12.85 18.47 13.4 18.72 13.88C19.79 15.04 22.97 15.39 22.99 16.78C23.01 18.17 20.41 18.78 17 18.78C15.31 18.86 13.61 18.6 12.01 18.02Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 17.84C18.53 19.35 15.7 20 12.01 20C8.32 20 5.5 19.34 5.5 17.84C5.5 16.34 8.94 15.96 10.11 14.7C10.4 14.19 10.48 13.59 10.34 13.02C9.93 12.83 9.6 12.25 9.58 11.02C9.2 11.02 8.96 10.89 8.89 10.68C8.49 9.95 8.49 9.07 8.89 8.34C8.13 5.58 9.93 4 12.01 4C14.09 4 15.88 5.57 15.13 8.34C15.53 9.07 15.53 9.95 15.13 10.68C15.06 10.89 14.82 11.02 14.44 11.01C14.42 12.24 14.11 12.82 13.7 13.01C13.54 13.57 13.61 14.18 13.89 14.69C15.04 15.96 18.48 16.33 18.5 17.84Z"
    />
  </svg>
)
