import React from 'react'

export const Clapper = () => (
  <svg width="68" height="59" viewBox="0 0 68 59" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        opacity="0.7"
        d="M4.37302 58.343C2.53302 58.343 1.04302 56.853 1.04302 55.013V21.743C1.04302 21.133 1.54302 20.633 2.15302 20.633H66.483C67.093 20.633 67.593 21.133 67.593 21.743V55.013C67.593 56.853 66.103 58.343 64.263 58.343H4.37302ZM0.763018 19.723L0.0730154 16.473C-0.306985 14.673 0.843021 12.913 2.63302 12.523L61.213 0.0730005C63.013 -0.307 64.773 0.842998 65.163 2.633L65.853 5.883C65.983 6.483 65.593 7.073 65.003 7.193L2.08302 20.573C1.48302 20.713 0.893018 20.333 0.763018 19.723C0.763018 19.733 0.763018 19.733 0.763018 19.723Z"
        fill="#FFFFFF66"
      />
      <path
        opacity="0.7"
        d="M46.803 46.473L44.503 42.363C44.413 42.193 44.343 42.013 44.273 41.833H44.243C44.193 41.943 44.123 42.123 44.013 42.363L41.643 46.473H39.403L43.073 40.843L39.693 35.273H41.963L43.983 39.053C44.113 39.303 44.233 39.553 44.333 39.803H44.353C44.503 39.473 44.633 39.213 44.743 39.023L46.843 35.273H48.933L45.483 40.823L49.003 46.473H46.803ZM31.813 46.473V35.273H37.863V36.853H33.673V40.223H37.533V41.793H33.673V46.483L31.813 46.473ZM23.423 46.473L19.473 35.273H21.503L24.273 43.773C24.373 44.083 24.433 44.393 24.473 44.723H24.503C24.543 44.393 24.623 44.073 24.733 43.763L27.563 35.283H29.533L25.513 46.483H23.423V46.473ZM55.393 26.183L58.353 20.633H64.273L61.313 26.183H55.393ZM43.193 26.183L46.153 20.633H52.073L49.113 26.183H43.193ZM30.993 26.183L33.953 20.633H39.873L36.913 26.183H30.993ZM18.793 26.183L21.753 20.633H27.673L24.713 26.183H18.793ZM6.59301 26.183L9.55301 20.633H15.473L12.513 26.183H6.59301ZM5.27301 14.233L11.063 13.003L15.113 17.813L9.32301 19.043L5.27301 14.233ZM17.203 11.703L22.993 10.473L27.043 15.283L21.253 16.513L17.203 11.703ZM29.143 9.16299L34.933 7.933L38.983 12.743L33.193 13.973L29.143 9.16299ZM41.073 6.623L46.863 5.39299L50.913 10.203L45.123 11.433L41.073 6.623ZM53.003 4.093L58.793 2.86299L62.843 7.673L57.053 8.903L53.003 4.093Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
